import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, ImageType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Gradient from './gradient'
import { xl } from '../utils/breakpoints'
import { usePageContext } from '../context/page-context'

const ImageHero = ({
  style,
  contentStyle,
  imageStyle,
  image,
  imageMobile,
  title,
  description,
  subtitle,
  callToAction,
  theme,
  buttonTheme,
  buttonStyle,
  gradStyle,
  hideCaret,
  withGradient,
  extended,
}) => {
  const { locale } = usePageContext()

  return (
    <div
      css={[
        css`
          ${tw`relative bg-white h-140
          sm:(h-170)
          md:(mb-0 h-176)
          lg:h-128
          xxl:(h-hero-default -mb-11)`}
          ${extended &&
          tw`-mt-18 h-hero-mobile-extended 
          lg:(-mt-20 h-hero-default-extended)`}
        `,
        style,
      ]} //: 'h-hero-mobile lg:h-hero-default' //last changes: h-140 -> h-120 / 186 -> 170
    >
      {withGradient && (
        <Gradient
          style={tw`hidden h-14 w-full place-content-start lg:flex`}
          firstColumnStyle={tw`from-primary-gradient to-primary-500 w-80 h-14`}
          lastColumnStyle={tw`h-14 w-14 bg-secondary-500`}
        />
      )}
      <div
        css={[global`layout.container`, global`layout.grid`, tw`absolute inset-0 xl:items-center`]}
      >
        <div
          css={[
            tw`flex flex-col justify-between order-last items-start col-span-4 md:col-span-8 
            lg:(order-none col-span-6 justify-center mt-8) 
            xl:(col-span-7 mt-0)`,
            contentStyle,
          ]}
        >
          <Heading headingType="h5" content={subtitle} style={[tw`font-medium opacity-60 `]} />
          <Heading
            headingType="h1"
            content={title}
            style={[
              css`
                ${tw`mt-8 lg:(mt-12 text-mobile-7xl leading-display-desktop)`}
                ${locale === 'zh-Hant-HK' && tw`lg:(whitespace-pre z-10)`}
                p {
                  ${tw`font-light`}
                  ${theme === 'navy' ? tw`text-primary-500` : tw`text-white`}
                }
                strong {
                  ${tw`font-bold`}
                }
                span {
                  ${tw`font-medium`}
                }
              `,
            ]}
          />
          {/* PEQUENO AZUL */}
          {withGradient && (
            <div css={tw`flex w-full pb-2 mt-4 lg:hidden`}>
              <Gradient
                style={[tw`h-8 w-fit-content absolute right-0 bottom-20 lg:hidden`, gradStyle]}
                firstColumnStyle={tw`w-8 h-8 bg-primary-500`}
                lastColumnStyle={tw`h-8 w-11 from-secondary-500 to-secondary-gradient`}
              />
            </div>
          )}
          {description && <Heading
            headingType="h4"
            content={description}
            style={[
              theme === 'navy' ? tw`text-primary-500` : tw`text-white`,
              tw`mt-8 font-normal lg:(mt-12)`,
            ]}
          />}
          {callToAction && (
            <Button
              type="primary"
              size="sm"
              theme={buttonTheme}
              label={callToAction.label}
              link={callToAction.link}
              style={[tw`mt-6 lg:mt-12`, buttonStyle]}
              hideCaret={hideCaret}
            />
          )}
          {/* YELLOW  */}
          {withGradient && (
            <div css={tw`flex w-full mt-13 bottom-0 sm:m-0 place-content-end lg:hidden`}>
              <Gradient
                style={tw`right-0 bottom-0 h-8 w-fit-content`}
                firstColumnStyle={tw`h-8  w-8 bg-accent-500`}
                lastColumnStyle={tw`h-8 w-44 lg:w-108 from-primary-500 to-primary-gradient`}
              />
            </div>
          )}
        </div>
        <div
          css={[
            tw`col-span-4 z-1 -mx-container-mobile md:col-span-8
            lg:(-mr-container-lg pl-28 col-span-7 col-start-7 order-none ) 
            xl:(pl-12 col-start-9 my-auto col-end-17 ml-0 -mr-container-xl mt-0) 
            xxl:(pl-0 col-start-9 -mr-container-xxl)`,
          ]}
        >
          <Image
            image={image}
            style={tw`h-auto md:(h-100 object-cover) lg:h-128 xxl:(h-187.5 w-236 )`}
            imageMobile={imageMobile}
          />

          {withGradient && (
            <Gradient
              style={tw`h-8 w-full place-content-start lg:hidden`}
              firstColumnStyle={tw`from-primary-gradient to-primary-500 w-60 h-8`}
              lastColumnStyle={tw`h-8 w-8 bg-secondary-500`}
            />
          )}
          {withGradient && (
            <>
              <div css={tw`hidden xl:(block absolute top-16 left-136) xxl:(top-24 left-148)`}>
                <Gradient
                  style={tw`relative h-14 w-fit-content`}
                  firstColumnStyle={tw`h-14  w-14 bg-primary-500`}
                  lastColumnStyle={tw`h-14 w-44 
                  lg:w-64 
                  xxl:w-96 from-secondary-500 via-secondary-500 to-secondary-gradient`}
                />
              </div>
              <div
                css={tw`hidden lg:(block absolute -mt-14 right-104) 
              xxl:(right-176)`}
              >
                <Gradient
                  style={tw`relative h-14 w-fit-content`}
                  firstColumnStyle={tw`h-14  w-14 bg-accent-500`}
                  lastColumnStyle={tw`from-primary-500 to-primary-gradient h-14 w-44 lg:w-52 xl:w-96 xxl:w-108`}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ImageHero.defaultProps = {
  theme: 'navy',
  buttonTheme: 'navy-white',
  hideCaret: 'false',
}

ImageHero.propTypes = {
  style: StyleType,
  buttonStyle: StyleType,
  contentStyle: StyleType,
  imageStyle: StyleType,
  image: ImageType,
  imageMobile: ImageType,
  title: HTMLType,
  description: HTMLType,
  subtitle: HTMLType,
  callToAction: LinkType,
  theme: PropTypes.oneOf(['white', 'navy']),
  buttonTheme: PropTypes.string,
  extended: PropTypes.bool,
  hideCaret: PropTypes.bool,
  withGradient: PropTypes.bool,
}

export default ImageHero
