import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { StyleType } from '../utils/prop-types'

const Gradient = ({ style, firstColumnStyle, lastColumnStyle }) => {
  return (
    <div css={[tw`flex`, style]}>
      <div css={[tw`bg-gradient-to-r`, firstColumnStyle]} />
      <div css={[tw`bg-gradient-to-r`, lastColumnStyle]} />
    </div>
  )
}

Gradient.propTypes = {
  style: StyleType,
  firstColumnStyle: StyleType,
  lastColumnStyle: StyleType,
}

export default Gradient